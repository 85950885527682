import { Controller } from "@hotwired/stimulus"

// This controller handles the addition and removal of nested forms.
// It's used for dynamically adding and removing nested fields in forms.
export default class extends Controller {
  static targets = ["template", "items", "count"]
  static values = {
    placeholder: { type: String, default: "NEW_RECORD" }
  }

  add(event) {
    event.preventDefault()

    const timestamp = new Date().getTime()
    const regexp = new RegExp(this.placeholderValue, 'g')
    const content = this.templateTarget.innerHTML.replace(regexp, timestamp)

    this.itemsTarget.insertAdjacentHTML('beforeend', content)
    this.updateCount()
  }

  remove(event) {
    event.preventDefault()
    const wrapper = event.target.closest('.nested-fields')

    if (wrapper.dataset.newRecord === "true") {
      // If it's a new record, just remove it from the DOM
      wrapper.remove()
    } else {
      // If it's an existing record, hide it and mark for deletion
      wrapper.style.display = 'none'
      wrapper.querySelector("input[name*='_destroy']").value = 1
    }

    this.updateCount()
  }

  updateCount() {
    if (this.hasCountTarget) {
      const visibleItems = this.itemsTarget.querySelectorAll('.nested-fields').length
      const hiddenItems = this.itemsTarget.querySelectorAll('.nested-fields[style*="display: none"]').length
      this.countTarget.textContent = `(${visibleItems - hiddenItems})`
    }
  }
}