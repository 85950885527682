import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="live-order-row"
export default class extends Controller {
  static values = {
    taskRemainingMinutes: Number,
    showTimeInfo: Boolean
  };

  static targets = ["timeInfo"];

  static classes = ["timeInfoNormal", "timeInfoAlert"]  

  connect() {
    this.refreshTimer = setInterval(() => {
      this.decreaseMinutes()
    }, 60000)
  }

  disconnect() {
    clearInterval(this.refreshTimer)
  }

  decreaseMinutes() {
    this.taskRemainingMinutesValue -= 1;

    this.updateTimeInfo();
  }

  updateTimeInfo() {
    if (!this.showTimeInfoValue) {
      return;
    }

    this.timeInfoTarget.textContent = this.timeInfoMessage(this.taskRemainingMinutesValue);

    this.applyTimeInfoStyle(this.taskRemainingMinutesValue);
  }

  applyTimeInfoStyle(time) {
    if (time < 0) {
      this.timeInfoTarget.classList.add(this.timeInfoAlertClass);
      this.timeInfoTarget.classList.remove(this.timeInfoNormalClass);
    } else {
      this.timeInfoTarget.classList.add(this.timeInfoNormalClass);
      this.timeInfoTarget.classList.remove(this.timeInfoAlertClass);
    }
  }

  timeInfoMessage(time) {
    if (time < 0) {
      return `Overdue by ${Math.abs(time)} m`;
    }

    return `Due in ${time} m`;
  }
}
