import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static values = {
    group: String,
    handle: String
  }

  connect() {
    this.sortable = new Sortable(this.element, {
      group: this.groupValue,
      handle: this.handleValue || false,
      animation: 150
    })
  }

}