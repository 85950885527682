import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "icon"]

  connect() {
    // Check for errors and expand if found
    if (this.contentTarget.querySelector('.text-red-600')) {
      this.contentTarget.classList.remove('hidden')
      this.iconTarget.classList.remove('rotate-180')
    }
  }

  toggle(event) {
    event.preventDefault()

    // If we're trying to hide the section, check for invalid fields
    if (!this.contentTarget.classList.contains('hidden')) {
      const invalidFields = this.contentTarget.querySelectorAll(':invalid')
      if (invalidFields.length > 0) {
        invalidFields[0].focus()
        return // Don't hide if there are invalid fields
      }
    }

    this.contentTarget.classList.toggle('hidden')
    this.iconTarget.classList.toggle('rotate-180')
  }
}