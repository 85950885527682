import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["itemTemplate", "itemContainer"]

  addItem() {
    const template = this.itemTemplateTarget

    const item = template.content.firstElementChild.cloneNode(true);

    this.replaceIndexInputElements(item)

    let container = this.itemContainerTarget
    container.append(item);
  }

  replaceIndexInputElements(element) {
    let elems = element.getElementsByTagName('input')

    for (let i = 0; i < elems.length; i++) {
      let elem = elems[i]
      elem.setAttribute("name", elem.name.replace("[9999]", `[${Date.now()}]`))
    }
  }

  onAddItemClick(event) {
    // event.preventDefault()

    this.addItem()
  }

  onRemoveClick(event) {
    let parent_button = event.srcElement.closest("button")

    parent_button.parentElement.parentElement.remove()
  }
}