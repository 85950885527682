import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="open-tab"
export default class extends Controller {
  static targets = [
    "voidItemPopover",
    "itemDescription",
    "itemVariantId",
    "itemQuantity",
    "tableSection"
  ]

  static values = {
    id: String
  }

  onItemRemove({ params}) {
    this.itemDescriptionTarget.innerHTML = params.description;
    this.itemVariantIdTarget.value = params.variantId;
    this.updateQuantityOptions(params.maxQuantity);

    this.toggleVoidItemPopover();
  }

  voidItemCancel() {
    this.toggleVoidItemPopover();
  }

  voidItemApply() {
    this.toggleVoidItemPopover();

    const variantId = this.itemVariantIdTarget.value;
    const quantity = this.itemQuantityTarget.value;

    this.removeOrderItem(variantId, quantity);
  }

  toggleVoidItemPopover() {
    this.voidItemPopoverTarget.classList.toggle("invisible");
  }

  updateQuantityOptions(maxQuantity) {
    const quantityOptions = this.buildQuantityOptions(maxQuantity);

    this.itemQuantityTarget.innerHTML = "";

    quantityOptions.forEach((quantity) => {
      const option = document.createElement("option");
      option.value = quantity;
      option.text = quantity;

      this.itemQuantityTarget.appendChild(option);
    });
  }

  buildQuantityOptions(maxQuantity) {
    let quantityOptions = [];

    for (let i = 1; i <= maxQuantity; i++) {
      quantityOptions.push(i);
    }

    return quantityOptions;
  }

  async removeOrderItem(variantId, quantity) {
    const token = document.querySelector("meta[name=csrf-token]").content;
    const response = await fetch(`/open_tabs/${this.idValue}/remove_item`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        {
          open_tab: {
            variant_id: variantId,
            quantity: quantity
          }
        }
      )
    });

    const htmlContent = await response.text();

    this.tableSectionTarget.outerHTML = htmlContent;
  }
}